export const paymentSurveyIds = {
  pix: 'csat-pix-payment-2024-05-13',
  boleto: 'csat-boleto-payment-2024-05-13',
} as const

export const guestPaymentSurveyIds = {
  guestPix: 'csat-guest-pix-payment-2024-06-28',
  guestBoleto: 'csat-guest-boleto-payment-2024-06-28',
} as const

export const onboardingSurveyIds = {
  onboardingFeedback: 'csat-onboarding-2024-05-27',
} as const

export const surveyIds = {
  ...paymentSurveyIds,
  ...guestPaymentSurveyIds,
  ...onboardingSurveyIds,
} as const

export type SurveyIds =
  | typeof paymentSurveyIds
  | typeof guestPaymentSurveyIds
  | typeof onboardingSurveyIds
