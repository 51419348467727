import dayjs from 'dayjs'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import usePreferences from './usePreferences'

import { analyticsEvents } from '~/res/constants/events'
import {
  guestPaymentSurveyIds,
  paymentSurveyIds,
  surveyIds,
} from '~/res/constants/surveys'
import { trackEvent } from '~/utils/analytics/events'
import { isValidSurveyIdForGroup } from '~/utils/surveys.util'

type SurveyDialogContextType = {
  shouldOpenSurveyDialog: boolean
  isDialogOpen: boolean
  openSurveyDialog: (
    surveyId?: keyof typeof surveyIds,
    options?: { onSurveyNotAvailable?: () => void },
  ) => void
  closeSurveyDialog: () => void
  isThanksToastOpen: boolean
  openThanksToast: () => void
  closeThanksToast: () => void
  surveyId: string | null
  setSurveyId: (surveyId: string | null) => void
}

export const SurveyDialogContext =
  createContext<SurveyDialogContextType | null>(null)

const paymentCSATPreferencesKey = 'payment-csat'

export const SurveyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [surveyId, setSurveyId] = useState<string | null>(null)
  const [isThanksToastOpen, setIsThanksToastOpen] = useState(false)
  const { preferences, set } = usePreferences()
  const latestCSATDate = preferences[paymentCSATPreferencesKey]
  const today = dayjs(new Date())

  const shouldOpenSurveyDialog = useMemo(() => {
    const moreThanAWeek = today.diff(dayjs(latestCSATDate), 'week', true) > 1

    return !latestCSATDate || moreThanAWeek
  }, [latestCSATDate, today])

  const openSurveyDialog = useCallback(
    (
      newSurveyId?: keyof typeof surveyIds,
      options?: { onSurveyNotAvailable?: () => void },
    ) => {
      const surveyIdValue = newSurveyId && surveyIds[newSurveyId]

      if (
        isValidSurveyIdForGroup(surveyIdValue, {
          ...paymentSurveyIds,
          ...guestPaymentSurveyIds,
        }) &&
        !shouldOpenSurveyDialog
      ) {
        if (options?.onSurveyNotAvailable) {
          options.onSurveyNotAvailable()
        }
        return
      }

      if (surveyIdValue) {
        setSurveyId(surveyIdValue)
      }

      setIsDialogOpen(true)
      set(paymentCSATPreferencesKey, today.toISOString())
      trackEvent({
        name: 'click_interaction',
        label: analyticsEvents.csat.open,
        interaction_result: surveyId ?? 'unknown',
      })
    },
    [set, shouldOpenSurveyDialog, surveyId, today],
  )

  const closeSurveyDialog = () => {
    setIsDialogOpen(false)
  }

  const openThanksToast = () => {
    setIsThanksToastOpen(true)
  }

  const closeThanksToast = () => {
    setIsThanksToastOpen(false)
  }

  const providerValue = useMemo(() => {
    return {
      isDialogOpen,
      shouldOpenSurveyDialog,
      openSurveyDialog,
      closeSurveyDialog,
      isThanksToastOpen,
      openThanksToast,
      closeThanksToast,
      surveyId,
      setSurveyId,
    }
  }, [
    isDialogOpen,
    isThanksToastOpen,
    openSurveyDialog,
    shouldOpenSurveyDialog,
    surveyId,
  ])

  return (
    <SurveyDialogContext.Provider value={providerValue}>
      {children}
    </SurveyDialogContext.Provider>
  )
}

export const useSurveyDialog = () => {
  const context = useContext(SurveyDialogContext)

  if (!context) {
    throw new Error('useSurveyDialog must be used inside the SurveyProvider')
  }

  return context
}
